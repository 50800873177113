import {
  Box,
  Heading,
  Image,
  Text,
  Grid,
  GridItem,
  HStack,
  Card,
  CardBody,
  VStack, Skeleton,
} from "@chakra-ui/react";
import logo from "../../../assets/images/logo.svg";
import drsImg from "../../../assets/images/doctors.jpg";

interface LoginPageInterface {
  redirectTo?: string;
}

export const AccessTokenLoading = (props: LoginPageInterface) => {

  return (
    <Grid
      minH="100vh"
      templateAreas={
        `"header"
        "main"
        "footer"`
      }
      gridTemplateRows={"60px 1fr 80px"}
      gridTemplateColumns={"1fr"}
      h="full"
      gap="1"
      fontWeight="bold"
    >
      <GridItem
        area={"header"}
        borderBottom="1px solid"
        borderColor="gray.200"
        px="30px"
      >
        <HStack alignItems="center" h="full">
          <Image src={logo} width="55px"></Image>
          <Text fontSize="20px" fontWeight="normal">
            Trinity Skin Clinic
          </Text>
        </HStack>
      </GridItem>
      <GridItem
        area={"main"}
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <HStack w="full" maxW="container.xl">
          <Box w={{ base: "full", md: "45%" }} p="15px">
            <Card borderRadius="24px">
              <CardBody p="35px" textAlign="center" justifyContent="center">
                <Image
                  src={logo}
                  w="144px"
                  mb="27px"
                  display="inline-block"
                ></Image>

                <Heading fontSize="36px" fontWeight="bolder" mb="27px">
                  Trinity Skin Clinic Account Login
                </Heading>

                <>
                  <VStack spacing="4" align="stretch">
                    <Skeleton height="5" width="100%" mb="30px" />
                    <Skeleton height="5" width="150px" />
                    <Skeleton height="10" width="100%" />
                    <Skeleton height="10" width="150px" borderRadius="25px" mx="auto" />
                  </VStack>
                </>

              </CardBody>
            </Card>
          </Box>
          <Box w="55%" p="15px" display={{ base: "none", md: "flex" }}>
            <Image src={drsImg} w="full" maxW="full" />
          </Box>
        </HStack>
      </GridItem>
      <GridItem area={"footer"} justifyContent="center">
        <Box
          maxW="768px"
          w="full"
          borderTop="1px solid"
          borderColor="gray.200"
          mx="auto"
        >
          <Text textAlign="center" fontSize="13px" pt="15px" color="gray">
            Read our privacy policy
          </Text>
        </Box>
      </GridItem>
    </Grid>
  );
};
