import {
  Flex,
  Image,
  Text,
  Button,
  HStack,
  Container,
  Icon,
  Circle,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import logo from "../../../assets/images/logo.svg";
import { FiLogOut } from "react-icons/fi";
import useAuth from "../../../hooks/useAuth";
import { hasuraRoles } from "../../../constants/roles";

interface PageHeaderProps {}

export const PageHeader = (props: PageHeaderProps) => {
  const { roles } = useAuth();

  return (
    <Container maxW="container.xl" h="full">
      <Flex
        h="full"
        w="full"
        justifyContent="space-between"
        alignItems="center"
        bg="white"
        borderRadius="12px"
        px={{ base: "5px", sm: "30px" }}
      >
        <HStack alignItems="center" spacing="20px">
          <Image width="56px" src={logo}></Image>
          <Text fontSize="20px" fontWeight="500">
            Trinity Skin Clinic
          </Text>
        </HStack>

        <HStack alignItems="center" spacing="20px">
          {roles.includes(hasuraRoles.ADMINISTRATOR) && (
            <Button
              variant={"ghost"}
              borderRadius="100px"
              fontWeight="500"
              fontSize="15px"
              py="10px"
              px="20px"
              as={ReactRouterLink}
              to="/admin"
            >
              Admin
            </Button>
          )}
          <Circle
            border="2px solid"
            borderColor="gray.200"
            size="44px"
            bg="white"
            cursor="pointer"
            onClick={() => {
              localStorage.clear();
              document.cookie = "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              window.location.href = "/dashboard/login";
            }}
          >
            <Icon fontSize="24px" color="gray.500" as={FiLogOut}></Icon>
          </Circle>
        </HStack>
      </Flex>
    </Container>
  );
};
