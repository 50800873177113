import {
  Box,
  Heading,
  Image,
  Text,
  Container,
  HStack,
  VStack,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Divider,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import doctor from "../../../assets/images/doctor.png";
import PatientSearchBox from "../../../components/FromsElements/PatientSearchBox";
import { GET_USER_DATA } from "./queries";
import { useLazyQuery } from "@apollo/client";
import useAuth from "../../../hooks/useAuth";
import { useEffect, useState } from "react";
import { ConsultationFormWidget } from "./components/ConsultationFormWidget";
import { backendApiInstance } from "../../../helpers/backendApiInstance";
import { AppointmentsFormWidget } from "./components/AppointmentsWidget";
import dayjs from "dayjs";

export const Home = () => {
  const { user_id, accessToken } = useAuth();
  const [getUserData, { error, loading, data, called }] = useLazyQuery(
    GET_USER_DATA,
    {
      variables: {
        id: user_id,
      },
    }
  );

  useEffect(() => {
    if (user_id > 0) {
      getUserData();
    }
  }, [user_id]);

  const apiInstance = backendApiInstance(accessToken);
  const [appointments, setAppointments] = useState<Array<any>>([]);
  const [squpIsLoading, setSqupIsLoading] = useState<boolean>(true);

  const startAtMin = dayjs().startOf("day").toISOString();
  const startAtMax = dayjs().add(29, "day").endOf("day").toISOString();

  // temporary code, should put into backend.
  useEffect(() => {
    apiInstance.default
      .getBookings(startAtMin, startAtMax)
      .then((data) => {
        setSqupIsLoading(false);
        setAppointments(data.bookings);
      })
      .catch((err) => {
        setSqupIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      apiInstance.default
        .getBookings(startAtMin, startAtMax)
        .then((data) => {
          setAppointments(data.bookings);
        })
        .catch((err) => {});
    }, 600000); // 10 minutes in milliseconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Container maxW="container.md">
      <HStack
        alignItems="flex-end"
        bg="white"
        w="full"
        h="76px"
        my="46px"
        boxShadow="sm"
        borderRadius="16px"
        border="0"
      >
        <VStack
          flexGrow="1"
          h="full"
          justifyContent="center"
          alignItems="start"
          ps="40px"
        >
          <Heading
            fontSize={{ base: "16px", sm: "20px", md: "26px" }}
            fontWeight="700"
          >
            Welcome,{" "}
            <Text as="span" color="blue" fontWeight="bolder">
              {data?.users_by_pk?.title}
            </Text>
          </Heading>
        </VStack>
        <Image w="119px" src={doctor} />
      </HStack>

      <Box>
        <PatientSearchBox></PatientSearchBox>
      </Box>
      <HStack my="30px" w="full" justifyContent="center">
        <Button
          as={ReactRouterLink}
          to="/dashboard/patients/list/"
          variant="outline"
        >
          See Patients List
        </Button>
        <Button
          as={ReactRouterLink}
          to="/consultation/request"
          variant="outline"
          target="_blank"
        >
          + New Consultation Request
        </Button>
      </HStack>
      <Box w="full" mt="50px">
        <Tabs variant="solid-rounded">
          <TabList>
            <Tab>Consultation Forms</Tab>
            <Tab>
              Upcoming Appointments
              {appointments?.length > 0 && ` (${appointments.length})`}{" "}
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Divider mb={5} />

              <ConsultationFormWidget />
            </TabPanel>

            <TabPanel>
              <Divider mb={5} />
              <AppointmentsFormWidget
                isLoading={squpIsLoading}
                appointments={appointments}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Container>
  );
};
