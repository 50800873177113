import {
  Link as ReactRouterLink,
  useParams,
  useNavigate,
} from "react-router-dom";
import useCustomToast from "../../../../hooks/useCustomToast";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_VISIT_DATA } from "./queries";
import {
  DeleteVisitNoteMutation,
  DeleteVisitNoteMutationVariables,
  DeleteVisitPrescriptionMutation,
  DeleteVisitPrescriptionMutationVariables,
  GetVisitDataQuery,
  GetVisitDataQueryVariables,
  Treatment_Prescriptions,
  UpdateVisitByPkMutation,
  UpdateVisitByPkMutationVariables,
} from "../../../../gql/graphql";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  HStack,
  Heading,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Text,
  VStack,
  useDisclosure, Flex, useBreakpointValue,
} from "@chakra-ui/react";
import { ChevronLeftIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import dayjs from "dayjs";
import PatientPictures from "./components/PatientPictures";
import { getPatientImageBasePath } from "../../../../helpers/S3Options";
import noNote from "../../../../assets/images/appointment_empty.svg";
import noteImage from "../../../../assets/images/note.svg";
import noPrescription from "../../../../assets/images/no-prescription.svg";
import { nl2br } from "../../../../helpers/StringUtil";
import { getDateTimeHRFormat } from "../../../../helpers/DateUtil";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineMail } from "react-icons/ai";
import { IoCopyOutline } from "react-icons/io5";
import Confirmation from "../../../../components/Confirmation";
import { DELETE_VISIT_NOTE, DELETE_VISIT_PRESCRIPTION } from "./mutations";
import { AddNoteModal } from "./modals/AddNoteModal";
import { AddPrescriptionModal } from "./modals/AddPrescriptionModal";
import { AddVisitModal } from "./modals/AddVisitModal";
import axiosInstance from "../../../../helpers/axiosInstance";
import { TreatmentForms } from "./components/TreatmentForms";
import { UPDATE_VISIT_BY_PK } from "../mutations";
import { FiDownloadCloud } from "react-icons/fi";
import useAuth from "../../../../hooks/useAuth";
import { backendApiInstance } from "../../../../helpers/backendApiInstance";
import {BiX} from "react-icons/bi";

export type SelectedNoteType = {
  id: string | null;
  text: string | null;
};

export const Visit = () => {
  const { accessToken } = useAuth();
  const navigate = useNavigate();
  let params = useParams<{ visitId: string; id: string }>();
  const toast = useCustomToast();

  const [addModalStoreType, setAddModalStoreType] = useState<'copy'|'edit'>('edit');
  const [addNoteModalIsOpen, setAddNoteModalIsOpen] = useState<boolean>(false);
  const [addPrescriptionModalIsOpen, setAddPrescriptionModalIsOpen] =
    useState<boolean>(false);
  const [editVisitModalIsOpen, setEditVisitModalIsOpen] =
    useState<boolean>(false);

  const defaultSelectedPrescriptionData = {
    id: null,
    text: null,
    prescription_id: -1,
  };
  const defaultSelectedNoteData: SelectedNoteType = {
    id: null,
    text: null,
  };
  const [selectedPrescription, setSelectedPrescription] = useState<
    typeof defaultSelectedPrescriptionData
  >({ ...defaultSelectedPrescriptionData });
  const [selectedNote, setSelectedNote] = useState<SelectedNoteType>({
    ...defaultSelectedNoteData,
  });

  const [deleteNoteModalIsOpen, setDeleteNoteModalIsOpen] =
    useState<boolean>(false);
  const [deletePrescriptionModalIsOpen, setDeletePrescriptionModalIsOpen] =
    useState<boolean>(false);
  const [currentTime, setCurrentTime] =
    useState<string|null>(null);
  const [currentDate, setCurrentDate] =
      useState<Date|null>(null);

  const {
    isOpen: isDeleteVisitOpen,
    onOpen: onDeleteVisitOpen,
    onClose: onDeleteVisitClose,
  } = useDisclosure();

  const [sendPrescriptionEmailObject, setSendPrescriptionEmailObject] =
    useState<any>(null);
  const [sendPrescriptionEmailBtnLoading, setSendPrescriptionEmailBtnLoading] =
    useState(false);

  const [getVisitData, { error, loading, data, refetch }] = useLazyQuery<
    GetVisitDataQuery,
    GetVisitDataQueryVariables
  >(GET_VISIT_DATA, {
    fetchPolicy: "no-cache",
    variables: {
      id: params.visitId,
    },
  });

  useEffect(() => {
    if (params.visitId) {
      getVisitData();
    }
  }, [params]);

  const [deleteVisitNote] = useMutation<
    DeleteVisitNoteMutation,
    DeleteVisitNoteMutationVariables
  >(DELETE_VISIT_NOTE);
  const [deleteVisitPrescription] = useMutation<
    DeleteVisitPrescriptionMutation,
    DeleteVisitPrescriptionMutationVariables
  >(DELETE_VISIT_PRESCRIPTION);

  const [updateVisitByPk] = useMutation<
    UpdateVisitByPkMutation,
    UpdateVisitByPkMutationVariables
  >(UPDATE_VISIT_BY_PK);

  const visit = data?.visits_by_pk;

  const prescriptionTemplates: Array<Partial<Treatment_Prescriptions>> = [];
  data?.visits_by_pk?.form_data?.forEach((formData) => {
    formData.treatment?.prescriptions.forEach((presc) => {
      if (prescriptionTemplates.some((x) => x.id === presc.id) === false) {
        prescriptionTemplates.push(presc);
      }
    });
  });

  const handleCopyVisit = async () => {
    const now = new Date();
    const formattedDate = new Date();
    const time = dayjs(now).format("HH:mm");
    setCurrentDate(formattedDate);
    setCurrentTime(time);
    setAddModalStoreType('copy');
    setEditVisitModalIsOpen(true);
  }

  const handleDownload = async () => {
    try {
      if (params.visitId) {

        toast.success("Generating report. Please wait...");

        const apiInstance = backendApiInstance(accessToken);

        const exportPdfResponse = await apiInstance.default.getApiV1VisitsExportPdf(params.visitId);
        const downloadUrl = exportPdfResponse.url;

        const response = await fetch(downloadUrl);
        if (!response.ok) {
          throw new Error('Failed to download file');
        }
        
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        // Create a name for the PDF file
        const pdfName = visit?.patient.first_name + '_' + visit?.patient.last_name + '_' + visit?.patient.customer_code + '.pdf';

        // Set the file name for the download
        link.setAttribute('download', pdfName);

        // Trigger the download
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);        

      };
    } catch (error) {
      toast.error("Generating report is failed.");
      console.error("Error downloading the PDF:", error);
    }
  };

  return (
    <Container w="full" maxW="container.xl" mt="15px">
      <HStack
        flexWrap="wrap"
        pt="10px"
        pb="10px"
        position={"sticky"}
        top={0}
        zIndex={"sticky"}
        backgroundColor={"#fAFAFA"}
      >
        <Button
          as={ReactRouterLink}
          to={`/dashboard/patient/${params.id}`}
          display="flex"
          bg="transparent"
          borderRadius="100px"
          h="48px"
          w="120px"
          border="1px solid"
          borderColor="gray.300"
        >
          <ChevronLeftIcon fontSize="xl"></ChevronLeftIcon>
          <Text fontSize="md" mx="10px">
            Back
          </Text>
        </Button>

        <Box>
          {visit && (
            <HStack flex={1} spacing="5" flexWrap="wrap">
              <Heading fontSize="30px" mx="15px">
                Visit #{visit.visit_number}
              </Heading>
              <Box display="flex" gap="20px" ml={5}>
                {visit.date && (
                  <Text fontWeight="500">
                    {dayjs(visit.date).format("D MMM YYYY")}
                  </Text>
                )}

                {visit.start_time && (
                  <Text fontWeight="500">{visit.start_time}</Text>
                )}

                {visit.end_time && visit.start_time && <Text>-</Text>}

                {visit.end_time && <Text fontWeight="500"> {visit.end_time}</Text>}
              </Box>

              <Box display="flex" gap="20px" ml={3}>
                <Button variant="btnNormal" onClick={handleDownload}>
                  <FiDownloadCloud />
                </Button>
                <Button
                  variant="btnNormal"
                  onClick={() => {
                    setCurrentDate(visit?.date ?? null);
                    setCurrentTime(visit?.start_time ?? null);
                    setAddModalStoreType('edit');                    
                    setEditVisitModalIsOpen(true);
                  }}
                >
                  <AiOutlineEdit />
                </Button>
                <Button variant="btnNormal" onClick={onDeleteVisitOpen}>
                  <AiOutlineDelete />
                </Button>
                <Button variant="btnNormal" onClick={handleCopyVisit}>
                  <IoCopyOutline />
                </Button>
              </Box>

              </HStack>
          )}
        </Box>
      </HStack>
      {visit && (
        <Box py="20px">
          <PatientPictures
            basePath={getPatientImageBasePath(visit.patient) + "visits/"}
            visit={visit}
          ></PatientPictures>

          <SimpleGrid
            mx="auto"
            mt="30px"
            w="full"
            maxW="full"
            minChildWidth={{
              base: "100%",
              lg: "50%",
            }}
          >
            <Card
              mb="30px"
              me={{ base: 0, lg: "15px" }}
              pb="15px"
              border="1px solid"
              borderColor="gray.300"
              boxShadow="none"
              borderRadius="16px"
              position="relative"
            >
              <CardHeader display="flex" justifyContent="space-between">
                <Heading fontSize="24px">Notes</Heading>
                <Button
                  fontSize="16px"
                  fontWeight="500"
                  color="#0065FF"
                  as="span"
                  cursor="pointer"
                  variant={"ghost"}
                  onClick={() => {
                    setSelectedNote(defaultSelectedNoteData);
                    setAddNoteModalIsOpen(true);
                  }}
                >
                  Add Note +
                </Button>
              </CardHeader>
              <CardBody
                p="30px"
                pt="0"
                minH="200px"
                maxH={{ base: "auto", md: "415px" }}
                overflowY="auto"
              >
                <VStack
                  w="full"
                  gap="4"
                  h="full"
                  justifyContent={visit.visit_notes?.length ? "top" : "center"}
                >
                  {!visit.visit_notes?.length && (
                    <>
                      <Image w="66px" src={noNote}></Image>
                      <Text fontSize="14px" fontWeight="400" color="#5C5C5C">
                        No notes
                      </Text>
                    </>
                  )}
                  {visit.visit_notes.map((note, index: number) => (
                    <Card
                      w="full"
                      key={index}
                      boxShadow="null"
                      borderRadius="12px"
                      border="1px solid"
                      borderColor="gray.300"
                      position="relative"
                    >
                      <CardHeader h="1px" p={0} position="relative">
                        <Image
                          src={noteImage}
                          position="absolute"
                          right="25px"
                          top="0"
                        ></Image>
                      </CardHeader>
                      <CardBody p="15px" pt="20px">
                        <Text
                          fontSize="15px"
                          fontWeight="400"
                          color="black"
                          dangerouslySetInnerHTML={{
                            __html: nl2br(note.note || ""),
                          }}
                        ></Text>
                      </CardBody>
                      <CardFooter
                        p="15px"
                        pt="0"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Text fontSize="14px" fontWeight="400" color="#2E2E2E">
                          {getDateTimeHRFormat(new Date(note.created_at))}
                        </Text>
                        <Menu>
                          <MenuButton
                            w="32px"
                            h="32px"
                            minW="32px"
                            as={IconButton}
                            aria-label="Options"
                            icon={
                              <HiOutlineDotsHorizontal
                                size="20"
                                color="black"
                              />
                            }
                            variant="link"
                            border="0px"
                          />
                          <MenuList>
                            <MenuItem
                              fontSize="14px"
                              icon={<EditIcon fontSize="16px" />}
                              onClick={() => {
                                setSelectedNote({
                                  id: note.id,
                                  text: note.note || "",
                                });
                                setAddNoteModalIsOpen(true);
                              }}
                            >
                              Edit Note
                            </MenuItem>
                            <MenuItem
                              fontSize="14px"
                              icon={<DeleteIcon fontSize="16px" />}
                              onClick={async () => {
                                setSelectedNote({
                                  id: note.id,
                                  text: note.note || "",
                                });
                                setDeleteNoteModalIsOpen(true);
                              }}
                            >
                              Delete Note
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </CardFooter>
                    </Card>
                  ))}
                </VStack>
              </CardBody>
            </Card>

            <Card
              mb="30px"
              ms={{ base: 0, lg: "15px" }}
              pb="15px"
              border="1px solid"
              borderColor="gray.300"
              boxShadow="null"
              borderRadius="16px"
            >
              <CardHeader display="flex" justifyContent="space-between">
                <Heading fontSize="24px">Prescriptions</Heading>
                <Button
                  fontSize="16px"
                  fontWeight="500"
                  color="#0065FF"
                  variant={"ghost"}
                  onClick={() => {
                    setSelectedPrescription(defaultSelectedPrescriptionData);
                    setAddPrescriptionModalIsOpen(true);
                  }}
                >
                  New Prescription +
                </Button>
              </CardHeader>
              <CardBody
                p="30px"
                pt="0"
                minH="200px"
                maxH={{ base: "auto", md: "415px" }}
                overflowY="auto"
              >
                <VStack
                  h="full"
                  justifyContent={
                    visit.visit_prescriptions?.length ? "top" : "center"
                  }
                >
                  {!visit.visit_prescriptions?.length && (
                    <>
                      <Image w="76px" src={noPrescription}></Image>
                      <Text fontSize="14px" fontWeight="400" color="#5C5C5C">
                        No prescriptions
                      </Text>
                    </>
                  )}
                  {visit.visit_prescriptions?.map(
                    (prescription: any, index: number) => (
                      <Card
                        w="full"
                        key={index}
                        boxShadow="null"
                        borderRadius="12px"
                        border="1px solid"
                        borderColor="gray.300"
                        position="relative"
                      >
                        <CardBody p="15px" pt="20px">
                          <Text
                            fontSize="15px"
                            fontWeight="400"
                            color="black"
                            dangerouslySetInnerHTML={{
                              __html: nl2br(prescription.text),
                            }}
                          ></Text>
                        </CardBody>
                        <CardFooter
                          p="15px"
                          pt="0"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          {/*<Text fontSize="14px" fontWeight="400" color="#2E2E2E">{getHRFormat(new Date(prescription.created_at))}</Text>*/}
                          <Menu>
                            <MenuButton
                              w="32px"
                              h="32px"
                              minW="32px"
                              as={IconButton}
                              aria-label="Options"
                              icon={
                                <HiOutlineDotsHorizontal
                                  size="20"
                                  color="black"
                                />
                              }
                              variant="link"
                              border="0px"
                            />
                            <MenuList>
                              <MenuItem
                                fontSize="14px"
                                icon={<EditIcon fontSize="16px" />}
                                onClick={() => {
                                  setSelectedPrescription({
                                    id: prescription.id,
                                    text: prescription.text,
                                    prescription_id:
                                      prescription.treatment_prescription_id ??
                                      -1,
                                  });
                                  setAddPrescriptionModalIsOpen(true);
                                }}
                              >
                                Edit Prescription
                              </MenuItem>
                              <MenuItem
                                fontSize="14px"
                                icon={<DeleteIcon fontSize="16px" />}
                                onClick={async () => {
                                  setSelectedPrescription({
                                    id: prescription.id,
                                    text: prescription.text,
                                    prescription_id:
                                      prescription.treatment_prescription_id ??
                                      -1,
                                  });

                                  setDeletePrescriptionModalIsOpen(true);
                                }}
                              >
                                Delete Prescription
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </CardFooter>
                      </Card>
                    )
                  )}
                </VStack>
              </CardBody>
              <CardFooter pb="5px" pt="15px">
                <Button
                  display="flex"
                  bg="transparent"
                  borderRadius="100px"
                  h="37px"
                  onClick={() => {
                    setSendPrescriptionEmailObject(true);
                  }}
                  isDisabled={visit.visit_prescriptions?.length === 0}
                  border="1px solid"
                  borderColor="gray.300"
                >
                  <Icon as={AiOutlineMail}></Icon>
                </Button>
              </CardFooter>
            </Card>
          </SimpleGrid>

          <TreatmentForms visit={visit} refetch={refetch} />
        </Box>
      )}

      <Confirmation
        isOpen={isDeleteVisitOpen}
        closeAction={onDeleteVisitClose}
        acceptAction={async () => {
          let result = await updateVisitByPk({
            variables: {
              id: params.visitId,
              object: {
                deleted_at: new Date(),
              },
            },
          });

          if (result.data?.update_visits_by_pk?.id) {
            toast.success("Visit deleted successfully");
          }
          navigate(`/dashboard/patient/${params.id}`);
        }}
      >
        <Text>Are you sure to delete the visit and it's records?</Text>
      </Confirmation>

      {deleteNoteModalIsOpen && (
        <Confirmation
          isOpen={deleteNoteModalIsOpen}
          closeAction={() => {
            setDeleteNoteModalIsOpen(false);
          }}
          acceptAction={async () => {
            let result = await deleteVisitNote({
              variables: {
                id: selectedNote.id,
              },
            });

            if (result.data?.delete_visit_notes_by_pk?.id) {
              toast.success("Note deleted successfully");
            }
            await refetch();
            setDeleteNoteModalIsOpen(false);
          }}
        >
          <Text>Are you sure to delete this Note?</Text>
        </Confirmation>
      )}

      {deletePrescriptionModalIsOpen && (
        <Confirmation
          isOpen={deletePrescriptionModalIsOpen}
          closeAction={() => {
            setDeletePrescriptionModalIsOpen(false);
          }}
          acceptAction={async () => {
            let result = await deleteVisitPrescription({
              variables: {
                id: selectedPrescription.id,
              },
            });

            if (result.data?.delete_visit_prescriptions_by_pk?.id) {
              toast.success("Prescription deleted successfully");
            }

            await refetch();
            setDeletePrescriptionModalIsOpen(false);
          }}
        >
          <Text>Are you sure to delete this Prescriptions?</Text>
        </Confirmation>
      )}

      {addNoteModalIsOpen && (
        <AddNoteModal
          isOpen={addNoteModalIsOpen}
          defaultState={selectedNote}
          closeAction={() => {
            setSelectedNote({ ...defaultSelectedNoteData });
            setAddNoteModalIsOpen(false);
          }}
          addAction={async () => {
            refetch();
            setSelectedNote({ ...defaultSelectedNoteData });
            setAddNoteModalIsOpen(false);
          }}
        ></AddNoteModal>
      )}

      {addPrescriptionModalIsOpen && (
        <AddPrescriptionModal
          defaultState={selectedPrescription}
          prescriptions={prescriptionTemplates}
          isOpen={addPrescriptionModalIsOpen}
          closeAction={() => {
            setAddPrescriptionModalIsOpen(false);
            setSelectedPrescription({ ...defaultSelectedPrescriptionData });
          }}
          addAction={async () => {
            await refetch();
            setSelectedPrescription({ ...defaultSelectedPrescriptionData });
            setAddPrescriptionModalIsOpen(false);
          }}
        ></AddPrescriptionModal>
      )}

      {editVisitModalIsOpen && (
        <AddVisitModal
          patient={null}
          isOpen={editVisitModalIsOpen}
          closeAction={async (id: string|null = null) => {
            setEditVisitModalIsOpen(false);
            setAddModalStoreType('edit');
            if (id) {
              navigate(`/dashboard/patient/${params.id}/visit/${id}`);
            } else {
              await refetch();
            }
          }}
          storeType={addModalStoreType}
          visit={visit}
          defaultState={{
            date: currentDate,
            duration: visit?.duration || null,
            id: visit?.id,
            end_time: visit?.end_time || null,
            patient_id: visit?.patient_id.toString() || null,
            start_time: currentTime,
          }}
        ></AddVisitModal>
      )}

      <Confirmation
        isOpen={sendPrescriptionEmailObject !== null}
        isLoading={sendPrescriptionEmailBtnLoading}
        closeAction={() => {
          setSendPrescriptionEmailObject(null);
        }}
        acceptAction={async () => {
          setSendPrescriptionEmailBtnLoading(true);
          try {
            let sendEmailResult = await axiosInstance().post(
              "/mail/send_by_template",
              {
                visit: params.visitId,
                template: "PRESCRIPTION",
              },
              {
                headers: {
                  Authorization: 'Bearer ' + accessToken,
                },
              }
            );

            if (sendEmailResult.data.status === 0) {
              toast.success("Email Sent Successfully.");
            } else {
              toast.error("There is an error on sending email.");
            }
          } catch (e) {
            toast.error("There is an error on sending email.");
          }
          setSendPrescriptionEmailBtnLoading(false);
          setSendPrescriptionEmailObject(null);
        }}
      >
        Are you sure you want to send this email to the patient?
      </Confirmation>
    </Container>
  );
};
