import { MainTemplate } from "../../components/DashboardTemplate/MainTemplate";
import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import { Login } from "./Login";
import useAuth from "../../hooks/useAuth";
import { AccessTokenLoading } from "./Login/AccessTokenLoading";

export const Dashboard = () => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true)

    const {user_id, accessToken} = useAuth();

    useEffect(() => {
        if (accessToken) {
            setIsLoggedIn(true);
        }

        setLoading(false);
    }, []);

    return(
        <>
            {!loading && isLoggedIn &&
                <MainTemplate>
                    <Outlet></Outlet>
                </MainTemplate>
            }

            {!loading && !isLoggedIn &&
                <Login redirectTo="/dashboard"></Login>
            }

            {loading &&
                <AccessTokenLoading />
            }
        </>
    )
}
