import { Link as ReactRouterLink, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Container,
  Heading,
  HStack,
  Text,
  Box,
  VStack,
  Grid,
  GridItem,
  Flex,
  Spinner,
  Divider,
  Image,
  CardHeader,
  SimpleGrid,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ADD_FORM_DATA_NOTE,
  UPDATE_FORM_DATA_NOTE,
  DELETE_FORM_DATA_NOTE,
  UPDATE_FORM_DATA_BY_PK,
} from "../mutations";
import { GET_PATIENT_FORM_DATA } from "../queries";

import { FormItem, SectionObject } from "../../../../interfaces/Interfaces";
import TextAreaBox from "../../../../components/FromsElements/TextAreaBox";
import emptyImage from "../../../../assets/images/appointment_empty.svg";
import useAuth from "../../../../hooks/useAuth";
import useCustomToast from "../../../../hooks/useCustomToast";
import { getHRFormat } from "../../../../helpers/DateUtil";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import Confirmation from "../../../../components/Confirmation";
import {
  AddFormDataNoteMutation,
  AddFormDataNoteMutationVariables,
  DeleteFormDataNoteMutation,
  DeleteFormDataNoteMutationVariables,
  GetPatientFormDataQuery,
  GetPatientFormDataQueryVariables,
  UpdateFormDataNoteMutation,
  UpdateFormDataNoteMutationVariables,
  UpdateFormVisitDataByPkMutation,
  UpdateFormVisitDataByPkMutationVariables,
} from "../../../../gql/graphql";
import { SectionFormGroup } from "../../../../components/FormRenderer/SectionFormGroup";
import { NoteModal } from "./NoteModal";
import { useDisclosureWithData } from "../../../../hooks/useDisclosureWithData";
import nl2br from "react-nl2br";
import { EditSectionModal } from "./EditSectionModal";
import { RootState } from "../../../../store/Store";
import { useSelector } from "react-redux";

interface ConsultationFormProps {}
const Index = (props: ConsultationFormProps) => {
  let params = useParams();
  const [getPatientFormData, { error, loading, data, refetch }] = useLazyQuery<
    GetPatientFormDataQuery,
    GetPatientFormDataQueryVariables
  >(GET_PATIENT_FORM_DATA, {
    fetchPolicy: "no-cache",
    variables: {
      id: Number(params.fid),
    },
  });
  const { user_id } = useAuth();
  const toast = useCustomToast();
  let menuDom: any = document.querySelector("#section_list_menu");
  let menuHolderDom: any = document.querySelector("#section_list_menu_holder");

  const [activeSection, setActiveSection] = useState("public_information");
  const [isMovingMenu, setIsMovingMenu] = useState(false);
  const [lastX, setLastX] = useState(0);
  const [left, setLeft] = useState(0);
  const [overviewData, setOverviewData] = useState<Array<any>>([]);
  const [notes, setNotes] = useState<Array<any>>([]);
  const [stateData, setStateData] = useState({
    note_text: "",
  });
  const [addNoteBtnLoading, setAddNoteBtnLoading] = useState(false);
  const [addFormDataNote] = useMutation<
    AddFormDataNoteMutation,
    AddFormDataNoteMutationVariables
  >(ADD_FORM_DATA_NOTE);
  const [updateFormDataNote] = useMutation<
    UpdateFormDataNoteMutation,
    UpdateFormDataNoteMutationVariables
  >(UPDATE_FORM_DATA_NOTE);
  const [deleteFormDataNote] = useMutation<
    DeleteFormDataNoteMutation,
    DeleteFormDataNoteMutationVariables
  >(DELETE_FORM_DATA_NOTE);

  const [updateFormData] = useMutation<
    UpdateFormVisitDataByPkMutation,
    UpdateFormVisitDataByPkMutationVariables
  >(UPDATE_FORM_DATA_BY_PK);

  useEffect(() => {
    if (data?.form_data_by_pk) {
      setNotes(data.form_data_by_pk.notes);
      let tempCards: Array<any> = [];
      let tempItems: Array<any> = [];
      let cardsIndex = 0;
      let currentDescriptor: string | null = null;

      if (data?.form_data_by_pk.form?.template.sections.length > 0) {
        data?.form_data_by_pk.form?.template.sections.map((section: any) => {
          if (
            section.descriptor !== "overview" &&
            section.descriptor !== "public_information"
          ) {
            if (section.forms.length > 0) {
              section.forms.map((form: any) => {
                if (form.parent && form.parent !== null) {
                } else if (currentDescriptor === null) {
                  tempItems.push(form);
                  currentDescriptor = form.descriptor;
                } else if (currentDescriptor === form.descriptor) {
                  tempItems.push(form);
                } else {
                  currentDescriptor = form.descriptor;
                  if (tempItems.length > 0) {
                    tempCards[cardsIndex] = {
                      title: tempItems[0].title,
                      items: tempItems,
                    };
                    cardsIndex++;
                    tempItems = [];
                  }

                  tempItems.push(form);
                }
              });
            }
            tempCards[cardsIndex] = {
              title: tempItems[0].title,
              items: tempItems,
              descriptor: section.descriptor,
            };
            cardsIndex++;
            tempItems = [];
          }
        });
      }

      setOverviewData(tempCards);
    }
  }, [data]);

  useEffect(() => {
    menuDom = document.querySelector("#section_list_menu");
    menuHolderDom = document.querySelector("#section_list_menu_holder");
  }, []);

  useEffect(() => {
    if (params.fid) {
      getPatientFormData();
    }
  }, [params]);

  let scrollTimer: any = null;

  const {
    isOpen: noteModalIsOpen,
    onClose: noteModalOnClose,
    onOpen: noteModalOnOpen,
    data: noteModalData,
  } = useDisclosureWithData<{ id?: number; note?: string; section: string }>();

  const {
    isOpen: noteDeleteModalIsOpen,
    onClose: noteDeleteModalOnClose,
    onOpen: noteDeleteModalOnOpen,
    data: noteDeleteModalData,
  } = useDisclosureWithData<{ id?: number }>();

  const {
    isOpen: editModalIsOpen,
    onOpen: editModalOnOpen,
    onClose: editModalOnClose,
    data: editModalData,
  } = useDisclosureWithData<{ section: string }>();

  const consultationState = useSelector(
    (state: RootState) => state.consultation
  );

  return (
    <Container w="full" maxW="container.xl" mt="35px">
      <HStack flexWrap="wrap">
        <Button
          as={ReactRouterLink}
          to={`/dashboard/patient/${params.pid}`}
          display="flex"
          bg="transparent"
          borderRadius="100px"
          h="48px"
          w="120px"
          border="1px solid"
          borderColor="gray.300"
        >
          <ChevronLeftIcon fontSize="xl"></ChevronLeftIcon>
          <Text fontSize="md" mx="10px">
            Back
          </Text>
        </Button>

        <Heading fontSize="30px" mx="15px">
          Consultation Form
        </Heading>
      </HStack>
      {data?.form_data_by_pk?.form?.template && (
        <Grid
          mt="30px"
          h="full"
          w="full"
          templateColumns="repeat(12, 1fr)"
          gap={5}
        >
          <GridItem colSpan={{ base: 12, lg: 9 }}>
            <Card w="full" mb="20px">
              <CardBody position="relative" px="45px" w="full">
                {left > 5 && (
                  <Flex
                    position="absolute"
                    left="0"
                    alignItems="center"
                    width="45px"
                    h="full"
                    top="0"
                    cursor="pointer"
                    justifyContent="center"
                    onClick={() => {
                      let obj = document.querySelector(
                        "#section_list_menu_holder"
                      ) as HTMLElement;
                      if (obj) {
                        obj.scrollTo(left - 70 > 0 ? left - 70 : 0, 0);
                      }
                    }}
                  >
                    <ChevronLeftIcon fontSize="30px"></ChevronLeftIcon>
                  </Flex>
                )}
                <Box
                  w="full"
                  h="35px"
                  overflowX="auto"
                  overflowY="hidden"
                  position="relative"
                  id="section_list_menu_holder"
                  onScrollCapture={(e) => {
                    if (scrollTimer !== null) {
                      clearTimeout(scrollTimer);
                    }
                    scrollTimer = setTimeout(function () {
                      setLeft(
                        (
                          document.querySelector(
                            "#section_list_menu_holder"
                          ) as HTMLElement
                        )?.scrollLeft || 0
                      );
                    }, 150);
                  }}
                >
                  <HStack
                    id="section_list_menu"
                    position="absolute"
                    left="0"
                    h="30px"
                    gap="0"
                  >
                    {data?.form_data_by_pk?.form?.template?.sections.map(
                      (section: SectionObject, index: number) => {
                        if (section.descriptor !== "overview") {
                          return (
                            <Text
                              userSelect="none"
                              overflow="hidden"
                              whiteSpace="nowrap"
                              key={section.descriptor + index}
                              textOverflow="ellipsis"
                              fontSize="16px"
                              px="15px"
                              fontWeight={
                                section.descriptor === activeSection
                                  ? "700"
                                  : "400"
                              }
                              textAlign="center"
                              cursor="pointer"
                              onClick={() => {
                                setActiveSection(section.descriptor);
                              }}
                            >
                              {section.title}
                              {section.descriptor === activeSection && (
                                <Box
                                  mt="5px"
                                  mx="auto"
                                  w="50%"
                                  h="3px"
                                  bg="blue.200"
                                ></Box>
                              )}
                            </Text>
                          );
                        }
                      }
                    )}
                  </HStack>
                </Box>

                {Math.abs(left) + menuHolderDom?.offsetWidth <
                  menuDom?.offsetWidth - 30 && (
                  <Flex
                    position="absolute"
                    right="0"
                    alignItems="center"
                    width="45px"
                    cursor="pointer"
                    justifyContent="center"
                    h="full"
                    top="0"
                    onClick={() => {
                      let obj = document.querySelector(
                        "#section_list_menu_holder"
                      ) as HTMLElement;

                      if (obj) {
                        obj.scrollTo(
                          left + 70 < obj.offsetWidth
                            ? left + 70
                            : obj.offsetWidth,
                          0
                        );
                      }
                    }}
                  >
                    <ChevronRightIcon fontSize="30px"></ChevronRightIcon>
                  </Flex>
                )}
              </CardBody>
            </Card>
            {activeSection === "public_information" && (
              <>
                <Card
                  w="full"
                  px="15px"
                  pt="10px"
                  minW={{ base: "full", md: "550px" }}
                >
                  <CardHeader>
                    <HStack
                      borderBottom="3px dashed"
                      borderColor="gray.200"
                      pb="15px"
                    >
                      <Heading fontSize="24px">Personal Information</Heading>
                    </HStack>
                  </CardHeader>
                  <CardBody>
                    <SimpleGrid w="full" maxW="full" minChildWidth="300px">
                      <VStack
                        width={{ base: "full", sm: "50%" }}
                        alignItems="start"
                        mb="15px"
                      >
                        <Text fontSize="14px" fontWeight="400" color="gray.500">
                          Given Name
                        </Text>
                        <Text fontSize="15px" fontWeight="500">
                          {data?.form_data_by_pk?.patient?.first_name}
                        </Text>
                      </VStack>
                      <VStack
                        width={{ base: "full", sm: "50%" }}
                        alignItems="start"
                        mb="15px"
                      >
                        <Text fontSize="14px" fontWeight="400" color="gray.500">
                          Surname
                        </Text>
                        <Text fontSize="15px" fontWeight="500">
                          {data?.form_data_by_pk?.patient?.last_name}
                        </Text>
                      </VStack>
                      <VStack
                        width={{ base: "full", sm: "50%" }}
                        alignItems="start"
                        mb="15px"
                      >
                        <Text fontSize="14px" fontWeight="400" color="gray.500">
                          Birthday
                        </Text>
                        <Text fontSize="15px" fontWeight="500">
                          {data?.form_data_by_pk?.patient?.birthday}
                        </Text>
                      </VStack>
                      <VStack
                        width={{ base: "full", sm: "50%" }}
                        alignItems="start"
                        mb="15px"
                      >
                        <Text fontSize="14px" fontWeight="400" color="gray.500">
                          Occupation
                        </Text>
                        <Text fontSize="15px" fontWeight="500">
                          {data?.form_data_by_pk?.patient?.occupation}
                        </Text>
                      </VStack>
                    </SimpleGrid>
                  </CardBody>
                </Card>

                <Card
                  w="full"
                  px="15px"
                  pt="10px"
                  minW={{ base: "full", md: "550px" }}
                  mt="15px"
                >
                  <CardHeader>
                    <HStack
                      borderBottom="3px dashed"
                      borderColor="gray.200"
                      pb="15px"
                    >
                      <Heading fontSize="24px">Contact Information</Heading>
                    </HStack>
                  </CardHeader>
                  <CardBody>
                    <SimpleGrid w="full" maxW="full" minChildWidth="300px">
                      <VStack
                        width={{ base: "full", sm: "50%" }}
                        alignItems="start"
                        mb="15px"
                      >
                        <Text fontSize="14px" fontWeight="400" color="gray.500">
                          Mobile Number
                        </Text>
                        <Text fontSize="15px" fontWeight="500">
                          {data?.form_data_by_pk?.patient?.mobile}
                        </Text>
                      </VStack>
                      <VStack
                        width={{ base: "full", sm: "50%" }}
                        alignItems="start"
                        mb="15px"
                      >
                        <Text fontSize="14px" fontWeight="400" color="gray.500">
                          Email
                        </Text>
                        <Text fontSize="15px" fontWeight="500">
                          {data?.form_data_by_pk?.patient?.email}
                        </Text>
                      </VStack>
                      <VStack
                        width={{ base: "full", sm: "50%" }}
                        alignItems="start"
                        mb="15px"
                      >
                        <Text fontSize="14px" fontWeight="400" color="gray.500">
                          Address
                        </Text>
                        <Text fontSize="15px" fontWeight="500">
                          {data?.form_data_by_pk?.patient?.address.full}
                        </Text>
                      </VStack>
                      <VStack
                        width={{ base: "full", sm: "50%" }}
                        alignItems="start"
                        mb="15px"
                      >
                        <Text fontSize="14px" fontWeight="400" color="gray.500">
                          Contact Method
                        </Text>
                        <Text fontSize="15px" fontWeight="500">
                          {data?.form_data_by_pk?.patient?.contact_method}
                        </Text>
                      </VStack>
                    </SimpleGrid>
                  </CardBody>
                </Card>

                <Card
                  w="full"
                  px="15px"
                  pt="10px"
                  minW={{ base: "full", md: "550px" }}
                  mt="15px"
                >
                  <CardHeader>
                    <HStack
                      borderBottom="3px dashed"
                      borderColor="gray.200"
                      pb="15px"
                    >
                      <Heading fontSize="24px">Emergency Contact</Heading>
                    </HStack>
                  </CardHeader>
                  <CardBody>
                    <SimpleGrid w="full" maxW="full" minChildWidth="300px">
                      <VStack
                        width={{ base: "full", sm: "50%" }}
                        alignItems="start"
                        mb="15px"
                      >
                        <Text fontSize="14px" fontWeight="400" color="gray.500">
                          Emergency Contact Full Name
                        </Text>
                        <Text fontSize="15px" fontWeight="500">
                          {
                            data?.form_data_by_pk?.patient?.emergency_contact
                              .emergency_contact_full_name
                          }
                        </Text>
                      </VStack>
                      <VStack
                        width={{ base: "full", sm: "50%" }}
                        alignItems="start"
                        mb="15px"
                      >
                        <Text fontSize="14px" fontWeight="400" color="gray.500">
                          Emergency Contact Phone
                        </Text>
                        <Text fontSize="15px" fontWeight="500">
                          {
                            data?.form_data_by_pk?.patient?.emergency_contact
                              .emergency_contact_phone
                          }
                        </Text>
                      </VStack>
                      <VStack
                        width={{ base: "full", sm: "50%" }}
                        alignItems="start"
                        mb="15px"
                      >
                        <Text fontSize="14px" fontWeight="400" color="gray.500">
                          Emergency Contact Email
                        </Text>
                        <Text fontSize="15px" fontWeight="500">
                          {
                            data?.form_data_by_pk?.patient?.emergency_contact
                              .emergency_contact_email
                          }
                        </Text>
                      </VStack>
                    </SimpleGrid>
                  </CardBody>
                </Card>
              </>
            )}

            {overviewData.length > 0 &&
              overviewData.map((card, key) => {
                return (
                  <SectionFormGroup
                    card={card}
                    data={data?.form_data_by_pk?.data}
                    isOpen={card.descriptor === activeSection}
                    noteModalOnOpen={({ id, note }) =>
                      noteModalOnOpen({ id, note, section: card.descriptor })
                    }
                    notes={notes.filter((n) => n.section === card.descriptor)}
                    key={key + card.descriptor}
                    onDeleteNote={(id) => noteDeleteModalOnOpen({ id })}
                    canEdit={true}
                    editModalOnOpen={() =>
                      editModalOnOpen({ section: card.descriptor })
                    }
                  />
                );
              })}
          </GridItem>

          <GridItem colSpan={{ base: 12, lg: 3 }}>
            <Card w="full" h="full">
              <CardBody>
                <Heading fontSize="24px" mb="15px">
                  {" "}
                  General Notes
                </Heading>
                <TextAreaBox
                  item={{
                    descriptor: "note_text",
                    title: "",
                    values: [],
                    placeholder: "Write your note",
                    options: {
                      max_character: 1500,
                      props: {
                        fontSize: "14px",
                        rows: 5,
                      },
                    },
                  }}
                  state={stateData}
                  inputItemHandler={(value: string, item: FormItem) => {
                    setStateData({ note_text: value });
                  }}
                ></TextAreaBox>
                <Button
                  mt="20px"
                  variant="btnMain"
                  w="full"
                  isDisabled={
                    addNoteBtnLoading || stateData.note_text.length === 0
                  }
                  onClick={async () => {
                    setAddNoteBtnLoading(true);

                    try {
                      await addFormDataNote({
                        variables: {
                          object: {
                            body: stateData.note_text,
                            form_data_id: Number(params.fid),
                            patient_id: Number(params.pid),
                            user_id: user_id,
                          },
                        },
                      });

                      setStateData({
                        note_text: "",
                      });

                      toast.success("Changes Saved.");

                      refetch();
                    } catch (err) {
                      toast.error("An error occurred during save.");
                    }

                    setAddNoteBtnLoading(false);
                  }}
                >
                  {addNoteBtnLoading && <Spinner mx="5px"></Spinner>}
                  Save Note
                </Button>
                <Divider my="30px"></Divider>
                <Heading fontSize="20px">Recent Notes</Heading>

                {notes.filter((n) => n.section === null)?.length > 0 ? (
                  <>
                    <VStack my="15px" gap="15px">
                      {notes
                        .filter((n) => n.section === null)
                        .map((note) => (
                          <Card
                            w="full"
                            key={note.id}
                            boxShadow="null"
                            borderRadius="16px"
                            border="1px solid"
                            borderColor="gray.100"
                          >
                            <CardBody>
                              <HStack w="full" justifyContent="space-between">
                                <Text fontWeight="700" fontSize="15px">
                                  {getHRFormat(new Date(note.created_at))}
                                </Text>
                                <Menu>
                                  <MenuButton
                                    w="32px"
                                    h="32px"
                                    minW="32px"
                                    as={IconButton}
                                    aria-label="Options"
                                    icon={
                                      <HiOutlineDotsHorizontal
                                        size="20"
                                        color="black"
                                      />
                                    }
                                    variant="link"
                                    border="0px"
                                  />
                                  <MenuList>
                                    <MenuItem
                                      fontSize="14px"
                                      icon={<EditIcon fontSize="16px" />}
                                      onClick={() => {
                                        noteModalOnOpen({
                                          id: note.id,
                                          note: note.body as string,
                                          section: note.section,
                                        });
                                      }}
                                    >
                                      Edit Note
                                    </MenuItem>
                                    <MenuItem
                                      fontSize="14px"
                                      icon={<DeleteIcon fontSize="16px" />}
                                      onClick={() => {
                                        noteDeleteModalOnOpen({ id: note.id });
                                      }}
                                    >
                                      Delete Note
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </HStack>

                              <Text
                                color="#5C5C5C"
                                fontSize="15px"
                                fontWeight="400"
                              >
                                {nl2br(note.body)}
                              </Text>
                            </CardBody>
                          </Card>
                        ))}
                    </VStack>
                  </>
                ) : (
                  <Box textAlign="center" mt="15px">
                    <Image src={emptyImage} m="auto" w="54px"></Image>
                    <Text fontSize="14px" fontWeight="400" color="gray">
                      There are currently no data
                    </Text>
                  </Box>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      )}

      <EditSectionModal
        isOpen={editModalIsOpen}
        onClose={editModalOnClose}
        data={data?.form_data_by_pk?.data}
        section={editModalData?.section || ""}
        onSave={async () => {
          try {
            if (data?.form_data_by_pk?.id) {
              await updateFormData({
                variables: {
                  id: Number(data.form_data_by_pk.id),
                  object: {
                    data: consultationState.formsData,
                  },
                },
              });

              refetch();
              toast.success("Change Saved.");
              editModalOnClose();
            }
          } catch (err) {
            toast.error("An error occurred during save.");
          }
        }}
      />

      <NoteModal
        onSave={async (data) => {
          try {
            if (!data.id) {
              await addFormDataNote({
                variables: {
                  object: {
                    body: data.note,
                    section: noteModalData?.section,
                    form_data_id: Number(params.fid),
                    patient_id: Number(params.pid),
                    user_id: user_id,
                  },
                },
              });
            } else {
              await updateFormDataNote({
                variables: {
                  id: Number(data.id),
                  body: data.note,
                },
              });
            }

            refetch();
            toast.success("Change Saved.");
            noteModalOnClose();
          } catch (err) {
            toast.error("An error occurred during save.");
          }
        }}
        isOpen={noteModalIsOpen}
        onClose={noteModalOnClose}
        defaultValues={{
          note: noteModalData?.note || "",
          id: noteModalData?.id,
        }}
      />

      <Confirmation
        isOpen={noteDeleteModalIsOpen}
        closeAction={noteDeleteModalOnClose}
        acceptAction={async () => {
          try {
            await deleteFormDataNote({
              variables: {
                id: Number(noteDeleteModalData?.id),
              },
            });

            toast.success("Changes Saved.");

            refetch();
            noteDeleteModalOnClose();
          } catch (err) {
            toast.error("Deleting note failed!");
          }
        }}
      >
        Are you sure to delete note?
      </Confirmation>
    </Container>
  );
};

export default Index;
