import axiosInstance from "../../helpers/axiosInstance";
import {useEffect, useState} from "react";
import {
    HStack,
    SimpleGrid,
    Spinner,
    Text,
    VStack,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator, Box, Icon, Flex, Image, Button,
} from "@chakra-ui/react";
import * as path from "path";
import {ChevronRightIcon} from "@chakra-ui/icons";
import {FiDownload} from "react-icons/fi";
import * as React from "react";
import FileIcon from "../FileIcon";
import UploadModal from "./modals/UploadModal";
import Confirmation from "../Confirmation";
import useCustomToast from "../../hooks/useCustomToast";
import CreateFolderModal from "./modals/CreateFolderModal";
import useAuth from "../../hooks/useAuth";
interface FileManagerProps {
    onSelect?: any,
    multiple?: boolean,
    type: string,
    path: string,
    fileTypes?: string
}

const FileManager = (props:FileManagerProps) => {

    const { accessToken } = useAuth();

    let breadCrumbPath = '';
    const toast = useCustomToast();
    const [files, setFiles] = useState<Array<any>>([]);
    const [folders, setFolders] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isDeleting, setIsDeleting] = useState(false);
    const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);
    const [deleteFileModalIsOpen, setDeleteFileModalIsOpen] = useState(false);
    const [createFolderModalIsOpen, setCreateFolderModalIsOpen] = useState(false);
    const [basePath, setBasePath] = useState(props.path);
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const getFiles = async (path:string) => {
        setIsLoading(true);

        let list = await axiosInstance().post('/storage/list', {
            key: path,
            type: props.type
        }, {
            headers: {
                "Authorization": 'Bearer ' + accessToken
            }
        });

        if (list?.data?.data?.files) {
            extractFolderAndFiles(path, list?.data?.data?.files);
        } else {
            setIsLoading(false);
        }

    }

    const deleteFile = async (file:any) => {
        try {
            let result = await axiosInstance().post('/storage/delete', {
                key: file.key,
                type: "public"
            }, {
                headers: {
                    "Authorization": 'Bearer ' + accessToken
                }
            });

            if (result.data.status === 0) {
                toast.success('File removed successfully.')

                setSelectedFile(null);

                getFiles(basePath);
            } else {
                toast.error('There is a problem on deleting this File.')
            }
        } catch (err) {
            toast.error('There is a problem on deleting this File.')
        }
    }

    const createFolder = async (folder:string) => {
        try {
            let result = await axiosInstance().post('/storage/create_folder', {
                keys: [basePath + folder],
                type: "public"
            }, {
                headers: {
                    "Authorization": 'Bearer ' + accessToken
                }
            });

            if (result.data.status === 0) {
                toast.success('Folder created successfully.')
                getFiles(basePath);
                setCreateFolderModalIsOpen(false);
            } else {
                toast.error('There is a problem on creating this folder.')
            }
        } catch (err) {
            toast.error('There is a problem on creating this Folder.')
        }
    }

    const extractFolderAndFiles = (path:string, keys:Array<any>) => {
        let filesList:Array<any> = [];
        let foldersList:Array<any> = [];

        for (let item of keys) {
            if (item.tag === '/') {
                if (props.fileTypes) {
                    let fileTypes = props.fileTypes.toLowerCase().split(',');
                    if (fileTypes.indexOf('.' + item.ext.toLowerCase()) !== -1 && item.type !== 'folder') {
                        filesList.push(item);
                    }
                } else {
                    if (item.type !== 'folder') {
                        filesList.push(item);
                    }
                }

                if (item.type === 'folder') {
                    foldersList.push(item);
                }

            }
        }
        setFolders(foldersList);
        setFiles(filesList);
        setIsLoading(false);
    }

    useEffect(() => {
        getFiles(basePath);
    }, [basePath]);

    const getBreadCrumbItems = () => {
        let items = basePath.split('/');
        let result = [
            {
                title: '/',
                path: '/'
            }
        ];
        for (let index in items) {
            if (items[index]) {
                if (parseInt(index) === 0) {
                    result.push({
                        title: items[index],
                        path: items[index] + '/'
                    });
                } else {
                    result.push({
                        title: items[index],
                        path: result[index].path + items[index] + '/'
                    });
                }
            }
        }
        return result;
    }

    return (
        <VStack w="full" position="relative" flex="1">
            <HStack w="full" flexDirection={{
                base: "column",
                md: "row"
            }}>
                <Breadcrumb w="full" flex="1" p="5px" border="1px solid" borderColor="#f1f1f1" borderRadius="8px" spacing='2px' separator={<ChevronRightIcon color='gray.500' />}>
                    { getBreadCrumbItems().map((path, index) =>
                        {
                            return (
                                <BreadcrumbItem cursor="pointer" borderRadius="8px" key={index} onClick={
                                    () => {
                                        setBasePath(path.path !== '/' ? path.path : '');
                                    }}
                                    _hover={{
                                        bg: "#f1f1f1"
                                    }}
                                >
                                    <Text px="10px" as="span">{path.title}</Text>
                                </BreadcrumbItem>
                            );
                        }
                    )}
                </Breadcrumb>

                <HStack>
                    <Button onClick={() => {setCreateFolderModalIsOpen(true)}}>New Folder</Button>
                    <Button onClick={() => {setUploadModalIsOpen(true);}}>Upload</Button>
                </HStack>
            </HStack>

            {selectedFile &&
                <VStack border="1px solid" borderColor="#f1f1f1" zIndex="22" w={
                    {
                        base: "full",
                        sm: "80%",
                        md: "50%",
                        lg: "30%"
                    }}
                    bg="white"
                    position="fixed"
                    right="0"
                    bottom="0"
                    top="70px"
                >
                    <VStack w="full" p="15px" maxH="200px" overflow="hidden">
                        {selectedFile.type === 'image' &&
                            <Image src={selectedFile.url} w="full"></Image>
                        }

                        {selectedFile.type === 'folder' &&
                            <FileIcon ext="folder" props={{
                                mt: "15px",
                                w: "85px"
                            }}></FileIcon>
                        }

                        {selectedFile.type !== 'image' && selectedFile.type !== 'folder' &&
                            <FileIcon ext={selectedFile.ext} props={{
                                mt: "15px",
                                w: "85px"
                            }}></FileIcon>
                        }
                        <Text>{selectedFile.name}</Text>
                    </VStack>

                    <HStack w="full" justifyContent="stretch" p="10px">
                        {props.onSelect && selectedFile.type !== 'folder' &&
                            <Button flex="1"
                                onClick={() => {
                                    props.onSelect(selectedFile);
                                    setSelectedFile(null);
                                }}
                            >Select This File</Button>
                        }
                        <Button flex="1"
                            onClick={() => { setDeleteFileModalIsOpen(true); }}
                        >Delete</Button>
                        <Button flex="1"
                            onClick={() => {setSelectedFile(null)}}
                        >Close</Button>
                    </HStack>
                </VStack>
            }

            <Flex w="full" flex="1" position="relative"

            >
                {isLoading &&
                    <HStack position="absolute" left="0" right="0" bottom="0" top="0" opacity="0.8" justifyContent="center" alignItems="center">
                        <Spinner></Spinner>
                    </HStack>
                }
                {!isLoading &&
                    <>
                        {!folders.length && !files.length &&
                            <HStack w="full" justifyContent="center">
                                <Text>Folder is empty</Text>
                            </HStack>
                        }
                        {(files.length > 0 || folders.length > 0) &&
                            <SimpleGrid w="full" h="full" columns={{
                                base: 1,
                                sm: 2,
                                md: 4,
                                lg: 8,
                                xl: 10
                            }}>
                                {folders.map((folder) =>
                                    <VStack w="full" h="full" key={folder.key} gap="5px"
                                        onDoubleClick={() => {
                                            setSelectedFile(null);
                                            setBasePath(folder.key);
                                        }}
                                        onClick={() => {
                                            setSelectedFile(folder);
                                        }}
                                    >
                                        <FileIcon ext="folder" props={{
                                            mt: "15px",
                                            w: "85px"
                                        }}></FileIcon>
                                        <Text textAlign="center" w="full" fontSize="13px" fontWeight="400" color="gray" px="10px" textOverflow="ellipsis" overflow="hidden">{folder.name}</Text>
                                    </VStack>
                                )}

                                {files.map((file) =>
                                    <VStack w="full" h="full" key={file.key} gap="5px"
                                        onClick={() => {
                                            setSelectedFile(file)
                                        }}
                                    >
                                        { file.type === 'image' &&
                                            <Image p="15px" src={file.url} w="full" maxH="100px" objectFit="cover"></Image>
                                        }
                                        { file.type !== 'image' &&
                                            <FileIcon ext={file.ext} props={{
                                                mt: "15px",
                                                w: "85px"
                                            }}></FileIcon>
                                        }
                                        <Text textAlign="center" w="full" fontSize="13px" fontWeight="400" color="gray" px="10px" textOverflow="ellipsis" overflow="hidden">{file.name}</Text>
                                    </VStack>
                                )}
                            </SimpleGrid>
                        }
                        {uploadModalIsOpen &&
                            <UploadModal fileTypes={props.fileTypes ?? ''} type={props.type} isOpen={uploadModalIsOpen} basePath={basePath}
                                closeAction={() => {
                                    setUploadModalIsOpen(false)
                                }}
                                addAction={async (file:any) => {
                                    getFiles(basePath);
                                    setUploadModalIsOpen(false);
                                }}
                            ></UploadModal>
                        }

                        {createFolderModalIsOpen &&
                            <CreateFolderModal folders={folders} type={props.type} isOpen={createFolderModalIsOpen} basePath={basePath}
                                closeAction={() => {
                                    setCreateFolderModalIsOpen(false)
                                }}
                                addAction={async (folder:string) => {
                                    await createFolder(folder);
                                }}
                            ></CreateFolderModal>
                        }

                        {deleteFileModalIsOpen &&
                            <Confirmation isOpen={deleteFileModalIsOpen} isLoading={isDeleting}
                                closeAction={() => {
                                    setDeleteFileModalIsOpen(false)
                                }}
                                acceptAction={async () => {
                                    setIsDeleting(true);
                                    await deleteFile(selectedFile);
                                    setIsDeleting(false);
                                    setDeleteFileModalIsOpen(false)
                                }}
                            >
                                Are you sure to delete <Text as="span" fontWeight="700">{selectedFile.name}</Text>?
                            </Confirmation>
                        }
                    </>
                }
            </Flex>


        </VStack>
    );
}

export default FileManager;