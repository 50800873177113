import {
  Text,
  Grid,
  GridItem,
  Container,
  Flex, Button
} from "@chakra-ui/react";


import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Link as ReactRouterLink,
  useLocation,
  useParams,
} from "react-router-dom";
import { SectionObject } from "../../interfaces/Interfaces";
import type { RootState } from "../../store/Store";

import { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  init,
  setFormData, setPatientData,
  setFormFinished, setValidator
} from "../../store/slice";
import { useQuery } from "@apollo/client";
import {
  GET_CONSULTATION_FORM_STRUCTURE,
} from "./queries";

import { getDbFormat } from "../../helpers/DateUtil";
import { FinalPage } from "./FinalPage";
import SimpleReactValidator from "simple-react-validator";
import {
  GetConsultationFormStructureQuery,
  GetConsultationFormStructureQueryVariables,
} from "../../gql/graphql";
import { backendApiInstance } from "../../helpers/backendApiInstance";
import { FormWizard } from "../../components/FormWizard";
import useAuth from "../../hooks/useAuth";
export const Consultation = () => {
  const consultationState = useSelector(
    (state: RootState) => state.consultation
  );
  const dispatch = useDispatch();
  const { data } = useQuery<
    GetConsultationFormStructureQuery,
    GetConsultationFormStructureQueryVariables
  >(GET_CONSULTATION_FORM_STRUCTURE, {
    fetchPolicy: "no-cache",
    context: {
      assumeRole: "public",
    },
    variables: {
      center_id: Number(process.env.REACT_APP_CENTER_ID),
    },
  });
  let { state } = useLocation();

  const [preState, setPreState] = useState<any>(null);

  const patientIndexes = ["first_name", "last_name"];

  const params = useParams<{ unique_id?: string }>();

  const { accessToken } = useAuth();
  const apiInstance = backendApiInstance(accessToken);

  useEffect(() => {
    setPatientData({
      ...consultationState.patient_data,
      unique_id: params.unique_id,
    });

    if(params.unique_id){
        // fetch data and put into patient
    }

  }, [params]);

  useEffect(() => {
    if (state && !preState) {
      setPreState(state);
    }
  }, [state]);

  useEffect(() => {
    if (preState && consultationState.formsData) {
      Object.keys(preState).map((key) => {
        if (preState[key]) {
          dispatch(
            setFormData({
              id: key,
              value: preState[key],
            })
          );
        }
      });

      setPreState(null);
    }
  }, [consultationState.formsData]);

  const addFormData = async () => {
    let result = await apiInstance.default.createForm({
        unique_id: consultationState.patient_data.unique_id as string,
        data: consultationState.formsData
    })

    return result;
  };

  useEffect(() => {
    if (
      consultationState.currentForm &&
      !consultationState.currentForm.validator
    ) {
      dispatch(
        setValidator(
          new SimpleReactValidator({
            element: (message: string) => {
              return (
                <Text fontSize="sm" color="red.500">
                  {message}
                </Text>
              );
            },
          })
        )
      );
    }
  }, [consultationState.currentForm]);

  useEffect(() => {
    if (data?.form_schemas && data?.form_schemas?.length > 0) {
      let formTemplate = data.form_schemas[0]?.template;
      let tempSection: SectionObject[] = [];

      formTemplate.sections.map((item: SectionObject) => {
        tempSection.push({
          title: item.title,
          schema: item.schema,
          descriptor: item.descriptor,
          selected: true,
          isCurrent: true,
          forms: item.forms,
        });
      });

      dispatch(
        init({
          sections: tempSection,
          currentForm: formTemplate.sections[0].forms[0],
          currentStep: 1,
          currentSection: 1,
          forms: [],
          patient_data: {
            completed: false,
          },
          form_finished: false,
        })
      );
    }
  }, [data]);

  useEffect(() => {
    let tempIsNotFilled = false;
    if (
      consultationState.sections[consultationState.currentSection - 1]
        ?.schema === "patients"
    ) {
      let tempData: any = {
        unique_id: consultationState.patient_data.unique_id,
        first_name: consultationState.formsData.first_name,
        last_name: consultationState.formsData.last_name,
        mobile: consultationState.formsData.mobile,
        email: consultationState.formsData.email,
        occupation: consultationState.formsData.occupation,
        address: {
          full: consultationState.formsData.address,
        },
        email_verified_at: getDbFormat(new Date(Date.now())),
        mobile_verified_at: getDbFormat(new Date(Date.now())),
        emergency_contact: {
          emergency_contact_full_name:
            consultationState.formsData.Emergency_contact_full_name,
          emergency_contact_phone:
            consultationState.formsData.Emergency_contact_phone,
          emergency_contact_email:
            consultationState.formsData.Emergency_contact_email,
        },
        birthday:
          consultationState.formsData.year +
          "-" +
          consultationState.formsData.month +
          "-" +
          consultationState.formsData.day,
        contact_method: consultationState.formsData.contact_method,
      };

      patientIndexes.map((index: string) => {
        if (!tempData[index] || tempData[index].length === 0) {
          tempIsNotFilled = true;
        }

        if (!!tempData[index] && tempData[index].constructor === Object) {
          Object.keys(tempData[index]).map((key) => {
            if (!tempData[index][key] || tempData[index][key].length === 0) {
              tempIsNotFilled = true;
            }
          });
        }
      });

      dispatch(setPatientData({ ...tempData, completed: !tempIsNotFilled }));
    }
  }, [consultationState.formsData]);

  const finalize = async () => {
    await addFormData();
    dispatch(setFormFinished());
  };

  return (
    <Grid
      templateAreas={`"header" "main" "footer"`}
      gridTemplateRows={"80px 0.99fr 30px"}
      gridTemplateColumns={"1fr"}
      h="100vh"
      gap="1"
    >
      <GridItem area={"header"}>
        <Container w="full" maxW="container.xl" h="100%">
          <Flex h="100%">
            <Button
              colorScheme={"blue"}
              variant={"link"}
              as={ReactRouterLink}
              to="/"
              display="flex"
            >
              <ArrowBackIcon fontSize="xl"></ArrowBackIcon>
              <Text fontSize="md" mx="20px">
                Home
              </Text>
            </Button>
          </Flex>
        </Container>
      </GridItem>
      <GridItem p="2" area={"main"}>
        <Container w="full" maxW="450px" h="100%" justifyContent="center">
          {consultationState.form_finished && <FinalPage></FinalPage>}
          <FormWizard title="Request New Consultation" onSubmit={finalize} />
        </Container>
      </GridItem>
      <GridItem p="2" area={"footer"}></GridItem>
    </Grid>
  );
};
