import { ColorModeScript, Text } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { store } from "./store/Store";
import { Provider } from "react-redux";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  ApolloLink,
} from "@apollo/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { setContext } from "@apollo/client/link/context";
import { ChakraProvider } from "@chakra-ui/react";

import { theme } from "./chakra-ui/theme";
import { Fonts } from "./chakra-ui/fonts";

import { Consultation } from "./pages/Consultation";
import { PatientResources } from "./pages/PatientResources";
import { ShowResource } from "./pages/PatientResources/ShowResource";
import { Login } from "./pages/Dashboard/Login";
import { Dashboard } from "./pages/Dashboard";
import { Home as DashboardHome } from "./pages/Dashboard/Home";
import Patient from "./pages/Dashboard/Patient";
import ConsultationForm from "./pages/Dashboard/Patient/ConsultationForm";
import FitzSkinForm from "./pages/Dashboard/Patient/FitzSkinForm";
import SecondConsultationForm from "./pages/Dashboard/Patient/SecondConsultationForm";
import Pictures from "./pages/Dashboard/Patient/Pictures";
import Documents from "./pages/Dashboard/Patient/Documents";
import { jwtDecode } from "jwt-decode";
import { Admin } from "./pages/Admin";
import { Home as AdminHome } from "./pages/Admin/Home";
import Treatments from "./pages/Admin/Treatments";
import AdminPatients from "./pages/Admin/Patients";
import TreatmentForm from "./pages/Admin/Treatments/TreatmentForm";
import Prescriptions from "./pages/Admin/Treatments/Prescriptions";
import PrescriptionForm from "./pages/Admin/Treatments/Prescriptions/PrescriptionForm";
import Users from "./pages/Admin/Users";
import UserForm from "./pages/Admin/Users/UserForm";
import AdminPatientForm from "./pages/Admin/Patients/PatientForm";
import TreatmentCategories from "./pages/Admin/TreatmentCategories";
import TreatmentCategoriesForm from "./pages/Admin/TreatmentCategories/TreatmentCategoriesForm";
import { Visit } from "./pages/Dashboard/Patient/Visit";
import { AdminFormSchama } from "./pages/Admin/FormSchema";
import { AdminFormSchemaForm } from "./pages/Admin/FormSchema/FormSchemaForm";
import ConsultationForms from "./pages/Dashboard/ConsultationForms";
import Patients from "./pages/Dashboard/Patients";
import PatientForm from "./pages/Dashboard/Patients/PatientForm";
import * as Squareup from "./pages/Admin/Squareup";
import FormGenerator from "./pages/Admin/FormGenerator";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  release: `${process.env.NODE_ENV}`,

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    process.env.REACT_APP_API_BASE_URL || "localhost",
    /^https:\/\/(api|api-dev)\.trinityskinclinic\.com\.au/,
  ],
  
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

const router = createBrowserRouter([
  {
    path: "/",
    element: <PatientResources />,
  },
  {
    path: "/consultation/request",
    element: <Consultation />,
  },
  {
    path: "/patients/resources",
    element: <PatientResources />,
  },
  {
    path: "/patients/resources/:descriptor",
    element: <ShowResource />,
  },
  {
    path: "/dashboard/login",
    element: <Login />,
  },
  {
    path: "/admin",
    element: <Admin />,
    children: [
      {
        path: "/admin/form_generator/:id",
        element: <FormGenerator />,
      },
      {
        path: "/admin/dashboard",
        element: <AdminHome />,
      },
      {
        path: "/admin/form_schemas/add",
        element: <AdminFormSchemaForm />,
      },
      {
        path: "/admin/form_schemas/edit/:id",
        element: <AdminFormSchemaForm />,
      },
      {
        path: "/admin/form_schemas/:p?/:k?",
        element: <AdminFormSchama />,
      },
      {
        path: "/admin/treatment_categories/add",
        element: <TreatmentCategoriesForm />,
      },
      {
        path: "/admin/treatment_categories/edit/:id",
        element: <TreatmentCategoriesForm />,
      },
      {
        path: "/admin/treatment_categories/:p?/:k?",
        element: <TreatmentCategories />,
      },
      {
        path: "/admin/treatments/add",
        element: <TreatmentForm />,
      },
      {
        path: "/admin/treatments/edit/:id",
        element: <TreatmentForm />,
      },
      {
        path: "/admin/treatments/:tid/prescriptions",
        element: <Prescriptions />,
      },
      {
        path: "/admin/treatments/:tid/prescriptions/add",
        element: <PrescriptionForm />,
      },
      {
        path: "/admin/treatments/:tid/prescriptions/edit/:id",
        element: <PrescriptionForm />,
      },
      {
        path: "/admin/treatments/:p?/:k?",
        element: <Treatments />,
      },
      {
        path: "/admin/users/add",
        element: <UserForm />,
      },
      {
        path: "/admin/users/edit/:id",
        element: <UserForm />,
      },
      {
        path: "/admin/users/:p?/:k?",
        element: <Users />,
      },
      {
        path: "/admin/patients/add",
        element: <AdminPatientForm />,
      },
      {
        path: "/admin/patients/edit/:id",
        element: <AdminPatientForm />,
      },
      {
        path: "/admin/patients/:p?/:k?",
        element: <AdminPatients />,
      },
      {
        path: "/admin/apps/squareup/redirect",
        element: <Squareup.Redirect />,
      },
      {
        path: "/admin/apps/squareup/connect",
        element: <Squareup.Connect />,
      },
    ],
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    children: [
      {
        path: "/dashboard/",
        element: <DashboardHome />,
      },
      {
        path: "/dashboard/patients/add",
        element: <PatientForm />,
      },
      {
        path: "/dashboard/patients/edit/:id",
        element: <PatientForm />,
      },
      {
        path: "/dashboard/patients/list/:p?/:k?",
        element: <Patients />,
      },
      {
        path: "/dashboard/consultation-forms/:p?/:k?",
        element: <ConsultationForms />,
      },
      {
        path: "/dashboard/patient/:id",
        element: <Patient />,
      },
      {
        path: "/dashboard/patient/:id/visit/:visitId",
        element: <Visit />,
      },
      {
        path: "/dashboard/patient/:pid/form/:fid",
        element: <ConsultationForm />,
      },
      {
        path: "/dashboard/patient/:pid/skin/:fdid?",
        element: <FitzSkinForm />,
      },
      {
        path: "/dashboard/patient/:pid/pictures/",
        element: <Pictures />,
      },
      {
        path: "/dashboard/patient/:pid/documents/",
        element: <Documents />,
      },
      {
        path: "/dashboard/patient/:pid/second_consultation_form/:fdid?",
        element: <SecondConsultationForm />,
      },
    ],
  },
]);

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_BASE_URL}/v1/graphql`,
});

const authLink = setContext((_, { headers, assumeRole }) => {
  let decoded: any = null;
  let extraParams = {};
  const token = localStorage.getItem("access_token");

  if (token && assumeRole !== "public") {
    try {
      decoded = jwtDecode(token);

      if (decoded?.exp && decoded.exp * 1000 > Date.now()) {
        extraParams = {
          Authorization: `Bearer ${token}`,
        };
      }
    } catch (e) {

    }
  }

  return {
    headers: {
      ...headers,
      ...extraParams,
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache(),
});

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<>Sorry, an error has occurred!</>}>
      <ColorModeScript />
      <Provider store={store}>
        <ApolloProvider client={client}>
          <ChakraProvider theme={theme}>
            <Fonts />
            <RouterProvider router={router} />
          </ChakraProvider>
        </ApolloProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
