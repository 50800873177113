import { Flex } from "@chakra-ui/react";
import { useLocation, useNavigate } from 'react-router-dom';
import { MainTemplate } from "../../components/AdminTemplate/MainTemplate";
import { Outlet, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { hasuraRoles } from "../../constants/roles";

export const Admin = () => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true)

    const { accessToken, roles } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (accessToken) {
            setIsLoggedIn(true);
        }

        setLoading(false);
    }, []);


    useEffect(() => {
        if (location.pathname === '/admin' || location.pathname === '/admin/') {
            navigate('/admin/dashboard')
        }
    }, [location]);

    return(
        <>
            {!loading && isLoggedIn &&
                <MainTemplate>
                    <Outlet></Outlet>
                </MainTemplate>
            }

            {!loading && !isLoggedIn &&
                <Navigate to="/dashboard/login" />
            }

            {!loading && !roles.includes(hasuraRoles.ADMINISTRATOR) &&
                <Navigate to="/dashboard" />
            }

            {loading &&
                <Flex position="fixed" top="0" right="0" left="0" bottom="0" bg="white" justifyContent="center" alignItems="center">
                    Loading
                </Flex>
            }
        </>
    )
}
