import { Box, HStack, Heading, VStack } from "@chakra-ui/react";
import ButtonBox from "../FromsElements/ButtonBox";
import { FormContainer } from "../FromsElements/FormContainer";
import StepperProgressBar from "../StepperProgressBar/indexV2";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import {
  extendForms,
  inCreaseSubmitTries,
  nextStep,
  prevStep,
  setFormData,
  setPatientData,
} from "../../store/slice";
import { backendApiInstance } from "../../helpers/backendApiInstance";
import { Form } from "../../interfaces/Interfaces";
import useAuth from "../../hooks/useAuth";

type PropTypes = {
  title: string;
  onSubmit: () => void;
  alwaysDisplaySubmitButton?: boolean;
};

export const FormWizard = (props: PropTypes) => {
  const { title, onSubmit, alwaysDisplaySubmitButton = false } = props;

  const { accessToken } = useAuth();
  const apiInstance = backendApiInstance(accessToken);

  const [isReadyToSubmit, setIsReadyToSubmit] = useState<boolean>(true);

  const dispatch = useDispatch();
  const inputHandler = async (value: any, item: any) => {
    if (typeof value === "object") {
      dispatch(setFormData(value));
    } else {
      dispatch(
        setFormData({
          id: item.descriptor,
          value: value,
        })
      );
    }
  };
  const consultationState = useSelector(
    (state: RootState) => state.consultation
  );

  const hasPrevStep = () => {
    if (consultationState.currentSection > 1) {
      return true;
    } else if (consultationState.currentSection === 1) {
      if (consultationState.currentStep > 1) {
        return true;
      }
    }

    return false;
  };

  const hasNextStep = () => {
    if (consultationState.sections.length > consultationState.currentSection) {
      if (
        consultationState.sections[consultationState.currentSection - 1].forms
          .length > consultationState.currentStep
      ) {
        return true;
      }
    } else if (
      consultationState.sections.length === consultationState.currentSection
    ) {
      if (
        consultationState.sections[consultationState.currentSection - 1].forms
          .length > consultationState.currentStep
      ) {
        return true;
      }
    }

    return false;
  };

  const hasNextSection = () => {
    if (consultationState.sections.length > consultationState.currentSection) {
      return !hasNextStep() && true;
    }

    return false;
  };

  const gotoNextStep = async () => {
    setIsReadyToSubmit(false);
    if (
      consultationState.currentForm &&
      consultationState.currentForm.validator
    ) {
      let formIsValid: boolean =
        consultationState.currentForm.validator.allValid();
      if (!formIsValid) {
        consultationState.currentForm.validator.showMessages();
        dispatch(inCreaseSubmitTries());
        setIsReadyToSubmit(true);
        return false;
      }
    }

    if (
      consultationState.currentSection &&
      consultationState.sections[consultationState.currentSection - 1]
        .schema === "patients"
    ) {
      if (consultationState.patient_data.completed) {
        try {
          // call upsertPatient
          const result = await apiInstance.default.createPatient({
            unique_id: consultationState.patient_data.unique_id,
            first_name: consultationState.patient_data.first_name as string,
            last_name: consultationState.patient_data.last_name as string,
            birthday: consultationState.patient_data.birthday as string,
            occupation: consultationState.patient_data.occupation,
            center_id: Number(process.env.REACT_APP_CENTER_ID),
            mobile: consultationState.patient_data.mobile,
            email: consultationState.patient_data.email,
            address: consultationState.patient_data.address,
            emergency_contact: consultationState.patient_data.emergency_contact,
            contact_method: consultationState.patient_data.contact_method,
          });

          dispatch(
            setPatientData({
              ...consultationState.patient_data,
              unique_id: result.data.unique_id,
            })
          );
        } catch (err) {
          setIsReadyToSubmit(true);
          return false;
        }
      }
    }

    dispatch(nextStep());
    setIsReadyToSubmit(true);
  };

  return (
    <>
      {!consultationState.form_finished && consultationState.currentForm && (
        <VStack>
          <Heading fontSize="2xl">{title}</Heading>
          <StepperProgressBar
            sections={consultationState.sections}
            activeSection={consultationState.currentSection}
            activeStep={consultationState.currentStep}
            nextStep={nextStep}
            prevStep={prevStep}
          ></StepperProgressBar>

          <FormContainer
            nextStep={nextStep}
            prevStep={prevStep}
            inputItemHandler={inputHandler}
            form={consultationState.currentForm as Form}
            currentStep={consultationState.currentStep}
            stepsCount={
              consultationState.sections[consultationState.currentSection - 1]
                .forms.length
            }
            state={consultationState.formsData}
            extendFormHandler={extendForms}
            validator={consultationState?.currentForm?.validator}
          ></FormContainer>
          <Box h="30px"></Box>

          {consultationState.currentForm &&
            !consultationState.currentForm?.hideSubmitButton &&
            hasNextStep() &&
            !hasNextSection() && (
              <ButtonBox
                item={{
                  title: "Continue",
                  descriptor: "goto_contact_info",
                  text: "",
                  type: "button",
                  action: {
                    onClick: () => {
                      gotoNextStep();
                    },
                  },
                  required: false,
                  placeholder: "",
                  default_value: "",
                  component: "",
                  options: {
                    type: "submit",
                    rightIcon: "ArrowForwardIcon",
                    props: {
                      isDisabled: !isReadyToSubmit,
                      w: "full",
                      variant: "btnMain",
                    },
                  },
                  values: {},
                  sub_items: [],
                }}
              ></ButtonBox>
            )}

          {consultationState.currentForm &&
            !consultationState.currentForm?.hideSubmitButton &&
            !hasNextStep() &&
            hasNextSection() && (
              <ButtonBox
                item={{
                  title: "Next Step",
                  descriptor: "goto_contact_info",
                  text: "",
                  type: "button",
                  action: {
                    onClick: () => {
                      gotoNextStep();
                    },
                  },
                  required: false,
                  placeholder: "",
                  default_value: "",
                  component: "",
                  options: {
                    type: "submit",
                    props: {
                      isDisabled: !isReadyToSubmit,
                      w: "full",
                      variant: "btnMain",
                    },
                  },
                  values: {},
                  sub_items: [],
                }}
              ></ButtonBox>
            )}

          <HStack px={10} width={'full'}>
            <VStack flex={1}>
            {consultationState.currentForm &&
              (alwaysDisplaySubmitButton ||
                !consultationState.currentForm?.hideSubmitButton) &&
              !hasNextStep() &&
              !hasNextSection() && (
                <ButtonBox
                  item={{
                    title: "Submit",
                    descriptor: "submit_form",
                    text: "",
                    type: "button",
                    action: {
                      onClick: onSubmit,
                    },
                    required: false,
                    placeholder: "",
                    default_value: "",
                    component: "",
                    options: {
                      type: "submit",
                      props: {
                        w: "full",
                        variant: "btnMain",
                      },
                    },
                    values: {},
                    sub_items: [],
                  }}
                ></ButtonBox>
              )}

            {consultationState.currentForm && hasPrevStep() && (
              <ButtonBox
                item={{
                  title: "Back",
                  descriptor: "",
                  text: "",
                  type: "button",
                  action: {
                    onClick: () => {
                      dispatch(prevStep());
                    },
                  },
                  required: false,
                  placeholder: "",
                  default_value: "",
                  component: "",
                  options: {
                    type: "submit",
                    props: {
                      w: "full",
                      variant: "link",
                    },
                  },
                  values: {},
                  sub_items: [],
                }}
              ></ButtonBox>
            )}
            </VStack>
          </HStack>
        </VStack>
      )}
    </>
  );
};
