import { decodeToken } from "react-jwt";
import axiosInstance from "../helpers/axiosInstance";
import { useEffect, useState } from "react";

const useAuth = () => {

  const accessToken = localStorage.getItem("access_token");
  let defaultUserId = null;
  let defaultRoles = [];

  if (accessToken) {
    const tokenPayload = decodeToken(accessToken);
    const payload = tokenPayload as any;
    const claims = payload?.custom_claims;

    if (payload?.exp && payload?.exp * 1000 > Date.now()) {
      defaultUserId = claims?.["x-hasura-user-id"];
      defaultRoles = claims?.["x-hasura-allowed-roles"] || [];
    }
  }

  const [authState, setAuthState] = useState<{
    user_id: any,
    roles: string[],
    accessToken: string | null
  }>({
    user_id: defaultUserId,
    roles: defaultRoles,
    accessToken,
  });

  useEffect(() => {
    const checkAndRefreshToken = async () => {
      let { accessToken } = authState;

      let validToken = false;

      if(accessToken != null){
        const tokenPayload = decodeToken(accessToken as string);
        const payload = tokenPayload as any;
        const claims = payload?.custom_claims;

        if (!payload?.exp || payload?.exp * 1000 < Date.now()) {
          accessToken = null;
        }
      }

      if (accessToken === null || accessToken !== authState.accessToken) {
        if(accessToken != null){
          const tokenPayload = decodeToken(accessToken as string);
          const payload = tokenPayload as any;
          const claims = payload?.custom_claims;  

          if (payload?.exp && payload?.exp * 1000 > Date.now()) {
            validToken = true;
            setAuthState({
              user_id: claims?.["x-hasura-user-id"],
              roles: claims?.["x-hasura-allowed-roles"] || [],
              accessToken,
            });            
          }
        }

        if (!validToken) {
          try {
            const response = await axiosInstance().post(
              "/auth/refresh-token",
              {},
              { 
                withCredentials: true,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },                
              }
            );
            const newAccessToken : string = response.data.data.token;

            localStorage.setItem("access_token", newAccessToken);

            const newTokenPayload = decodeToken(newAccessToken);
            const newPayload = newTokenPayload as any;
            const newClaims = newPayload?.custom_claims;

            setAuthState({
              user_id: newClaims?.["x-hasura-user-id"],
              roles: newClaims?.["x-hasura-allowed-roles"] || [],
              accessToken: newAccessToken,
            });
          } catch (error) {
            console.error("Error refreshing access token:", error);
            localStorage.removeItem("access_token");
            setAuthState({ user_id: null, roles: [], accessToken: null });
          }
        }
      }
    };

    checkAndRefreshToken();
  }, [accessToken]);

  return authState;
};

export default useAuth;
