import { BackendApi } from "../clients/backend";

export const backendApiInstance = (token: string | null) => {
  return new BackendApi({
    BASE: process.env.REACT_APP_API_BASE_URL,
    HEADERS: {
      "Content-Type": "application/json",
    },
    TOKEN: token !== null ? token : undefined
  });
};
