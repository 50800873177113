import ModalBox from "../../../../../components/ModalBox";
import { FiFilePlus } from "react-icons/fi";
import {
  Box,
  Button,
  Heading,
  VStack,
  HStack,
  Spinner,
  ModalFooter,
  ModalBody,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import useAuth from "../../../../../hooks/useAuth";
import useCustomToast from "../../../../../hooks/useCustomToast";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ADD_FORM_DATA, ADD_VISIT, UPDATE_VISIT_BY_PK } from "../../mutations";
import TimePickerBox from "../../../../../components/FromsElements/TimePickerBox";
import { addMinutesToATime } from "../../../../../helpers/DateUtil";
import DatePickerBox from "../../../../../components/FromsElements/DatePickerBox";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import Select from "../../../../../components/FormsElementsWithHook/Select";
import {
  AddFormVisitDataMutation,
  AddFormVisitDataMutationVariables,
  AddVisitMutation,
  AddVisitMutationVariables,
  GetVisitLastNumberByPatientIdQuery,
  GetVisitLastNumberByPatientIdQueryVariables,
  UpdateVisitByPkMutation,
  UpdateVisitByPkMutationVariables,
} from "../../../../../gql/graphql";
import { GET_VISIT_LAST_NUMBER_BY_PATIENT_ID } from "../queries";

type FormType = {
  id: string | null;
  start_time: string | null;
  end_time: string | null;
  duration: number | null;
  date: Date | null;
  patient_id: String | null;
};

interface AddVisitModalProps {
  isOpen: boolean;
  patient: any;
  closeAction: any;
  storeType?: "copy" | "edit";
  visit?: any;
  defaultState?: FormType;
}
export const AddVisitModal = (props: AddVisitModalProps) => {
  const { user_id } = useAuth();
  let params = useParams();

  const [addVisit] = useMutation<AddVisitMutation, AddVisitMutationVariables>(
    ADD_VISIT
  );
  const [updateVisitByPk] = useMutation<
    UpdateVisitByPkMutation,
    UpdateVisitByPkMutationVariables
  >(UPDATE_VISIT_BY_PK);
  const [getVisitLastNumberByPatientId] = useLazyQuery<
    GetVisitLastNumberByPatientIdQuery,
    GetVisitLastNumberByPatientIdQueryVariables
  >(GET_VISIT_LAST_NUMBER_BY_PATIENT_ID);

  const [InsertFormData, { loading: insertIsLoading }] = useMutation<
    AddFormVisitDataMutation,
    AddFormVisitDataMutationVariables
  >(ADD_FORM_DATA);

  const getDefaultNewState = () => ({
    id: null,
    date: new Date(),
    start_time: dayjs().format("HH:mm"),
    end_time: null,
    duration: 30,
    patient_id: null,
    user_id: null,
  });

  const [defaultState, _] = useState<FormType>(
    props.defaultState || getDefaultNewState()
  );

  const form = useForm<FormType>({
    defaultValues: defaultState,
  });
  const { reset, setValue, handleSubmit } = form;

  useEffect(() => {
    if (!props.isOpen) {
      reset(getDefaultNewState());
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (props.defaultState?.patient_id) {
      reset(props.defaultState);
    }
  }, [props.defaultState]);

  let durationValues: any = {
    0: {
      title: "Select Duration",
      value: 0,
    },
  };
  let step = 15;
  for (let i = 1; i <= 20; i++) {
    durationValues[i * step] = {
      title:
        Math.floor((i * step) / 60)
          .toString()
          .padStart(2, "0") +
        ":" +
        (((i * step) / 60 - Math.floor((i * step) / 60)) * 60)
          .toString()
          .padStart(2, "0"),
      value: i * step,
    };
  }

  const [isLoading, setIsLoading] = useState(false);
  const toast = useCustomToast();

  const onSubmit: SubmitHandler<FormType> = (data) => storeVisit(data);

  const copyVisit = async (
    id: any,
    start_time: any,
    end_time: any,
    duration: any,
    date: any
  ) => {
    let maxNumberResult = await getVisitLastNumberByPatientId({
      fetchPolicy: "network-only",
      variables: {
        patient_id: Number(params.id),
      },
    });

    const visit_number = isNaN(
      Number(
        maxNumberResult.data?.visits_aggregate.aggregate?.max?.visit_number
      )
    )
      ? 1
      : Number(
          maxNumberResult.data?.visits_aggregate.aggregate?.max?.visit_number
        ) + 1;

    let result = await addVisit({
      variables: {
        objects: [
          {
            patient_id: Number(params.id),
            user_id,
            start_time,
            end_time,
            duration,
            date,
            visit_number,
          },
        ],
      },
    });

    if (result.data?.insert_visits?.affected_rows) {
      let newId: string = result?.data?.insert_visits?.returning[0]?.id;
      await Promise.all(
        props.visit?.form_data.map(async (item: any) => {
          return InsertFormData({
            variables: {
              center_id: item.center_id,
              patient_id: Number(props?.visit?.patient_id),
              data: item.data,
              form_id: item.form_id,
              user_id: item.user_id,
              visit_id: newId,
              treatment_id: item.treatment_id,
            },
          });
        })
      );

      toast.success("Changes saved.");
      props.closeAction(newId);
    } else {
      toast.error("An error occurred during save. Please try again.");
    }
  };

  const storeVisit = async (data: FormType) => {
    const { id, start_time, duration, date } = data;

    setIsLoading(true);

    let end_time: any = null;
    if (data.start_time && data.duration) {
      end_time = addMinutesToATime(data.start_time as string, data.duration);
    }

    if (id) {
      if (props.storeType === "copy") {
        await copyVisit(id, start_time, end_time, duration, date);
      } else {
        let result = await updateVisitByPk({
          variables: {
            id,
            object: {
              start_time,
              end_time,
              duration,
              date,
            },
          },
        });

        if (result?.data?.update_visits_by_pk?.id) {
          toast.success("Changes saved.");
          props.closeAction();
        } else {
          toast.error("An error occurred during save. Please try again.");
        }
      }
    } else {
      // get max number
      let maxNumberResult = await getVisitLastNumberByPatientId({
        fetchPolicy: "network-only",
        variables: {
          patient_id: Number(params.id),
        },
      });

      const visit_number = isNaN(
        Number(
          maxNumberResult.data?.visits_aggregate.aggregate?.max?.visit_number
        )
      )
        ? 1
        : Number(
            maxNumberResult.data?.visits_aggregate.aggregate?.max?.visit_number
          ) + 1;

      let result = await addVisit({
        variables: {
          objects: [
            {
              patient_id: Number(params.id),
              user_id,
              start_time,
              end_time,
              duration,
              date,
              visit_number,
            },
          ],
        },
      });

      if (result.data?.insert_visits?.affected_rows) {
        toast.success("Changes saved.");
        props.closeAction();
      } else {
        toast.error("An error occurred during save. Please try again.");
      }
    }

    setIsLoading(false);
  };

  const addCancelButton = () => {
    return (
      <Button
        variant="btnNoraml"
        border="1px solid"
        borderColor="gray.400"
        w="full"
        borderRadius="100px"
        h="48px"
        mb="30px"
        onClick={() => {
          props.closeAction();
        }}
      >
        Cancel
      </Button>
    );
  };

  const addActionButton = () => {
    return (
      <Button
        variant="btnMain"
        border="1px solid"
        borderColor="gray.400"
        w="full"
        isDisabled={isLoading}
        borderRadius="100px"
        h="48px"
        mb="30px"
        type="submit"
      >
        Save
        {isLoading && (
          <>
            <Spinner />
          </>
        )}
      </Button>
    );
  };

  return (
    <FormProvider {...form}>
      <ModalBox
        isOpen={props.isOpen}
        onClose={() => {
          props.closeAction();
        }}
        size="xl"
        icon={FiFilePlus}
        iconsize="26px"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody pb={6}>
            <Box px="45px">
              <Heading size="md" textAlign="center">
                {props.storeType == "copy"
                  ? `Copy Visit (From Visit #${props.visit.visit_number})`
                  : defaultState?.id
                  ? "Edit visit"
                  : "Record new visit"}
              </Heading>

              <VStack w="full" mt="30px">
                <HStack spacing={5} mt="15px" w="full">
                  <DatePickerBox
                    w="50%"
                    item={{
                      descriptor: "date",
                      title: "Date",
                      values: {},
                    }}
                    validator={{ required: true }}
                  ></DatePickerBox>

                  <TimePickerBox
                    w="50%"
                    item={{
                      descriptor: "start_time",
                      title: "Start Time",
                      values: {},
                    }}
                    validator={{ required: true }}
                  ></TimePickerBox>
                </HStack>

                <HStack spacing={5} mt="15px" w="full">
                  <Select
                    w="100%"
                    item={{
                      descriptor: "duration",
                      title: "Duration",
                      values: durationValues,
                    }}
                    validator={{ required: true, min: 1 }}
                  ></Select>
                </HStack>
              </VStack>
            </Box>
          </ModalBody>

          <ModalFooter>
            <HStack
              w="full"
              align="center"
              spacing={10}
              marginStart={10}
              marginEnd={10}
              justifyContent={"space-between"}
            >
              {addCancelButton()}
              {addActionButton()}
            </HStack>
          </ModalFooter>
        </form>
      </ModalBox>
    </FormProvider>
  );
};
